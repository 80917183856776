<template>
  <div class="about-page">
    <!-- head -->
    <div class="max-width">
      <!-- content -->
      <v-row
        v-for="(item, i) in items"
        :key="i"
        class="mt-10"
        :class="item.reverse ? 'row-reverse' : ''"
      >
        <v-col cols="12" md="6">
          <span class="d-block font-30 font-500 primary--text"
            ><span class="d-block font-30 font-500 primary--text"
              >{{ $t("homepage.whatis") }} {{ $t("logo") }} ؟</span
            ></span
          >
          <p class="font-25 font-500 black--text my-3 mx-3" style="font-family: bold;">
            ANAPOG.COM is a social platform that connects travelers with those
            who want to bring or buy items from places travelers are traveling
            from. ANAPOG.COM is based on social networks to build trusted
            relations between people. Overnight shipping has never been easier.
            Your roommate lost the keys while on holiday and cannot get into the
            apartment, someone heading in that direction can deliver them to
            him/her. You talked to your grandma on the phone and graved her
            cookies; ANAPOG can find someone heading your way to bring them to
            you. <span class="green--text">There is something you want and is not available in your area,
            an Anapog traveler can purchase it and deliver it to you.</span> We’re
            revolutionizing the way people ship or buy and ship. ANAPOG.COM is a
            trusted community that enables travelers (trip owners) contribute
            their extra space to help people get the things they want.
            ANAPOG.COM is the easiest way for people to earn extra money while
            delivering a little happiness to new friends all over the world.
            We’re making it possible for you to earn money with your extra
            luggage space. Join us as we create a shipping community without
            borders.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          :class="
            $vuetify.breakpoint.mobile
              ? 'text-center'
              : item.reverse
              ? 'text-left d-flex justify-start'
              : 'text-right d-flex justify-end '
          "
        >
          <img width="90%" height="80%" :src="item.img" alt="" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        isTitle: true,
        img: require("@/assets/images/home/senders.png"),
        reverse: false,
      },
      {
        isTitle: false,
        img: require("@/assets/images/home/travelers.png"),
        reverse: true,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.about-page {
  &__head {
    background: linear-gradient(100.73deg, #ebebeb 0.97%, #c5cbf3 98.83%);
    margin: 5% 0px;
  }
}
</style>
